<template>
  <v-btn
    v-bind="{
      text: true,
      ...$attrs,
    }"
    v-on="$listeners"
  >
    <slot v-if="$slots.default" />
  </v-btn>
</template>

<script>
  export default {
    name: 'AppBtn',
    props: { path: String },
  }
</script>